import React from "react";
import { Link } from "react-router-dom";
import headerImage from "./assets/header-image.jpeg";
import sloganImage from "./assets/slogan-membership-campaign.png";
import membershipCampaignLogo from "./assets/membership-campaign.png";

export default function App() {
  return (
    <div className="max-w-4xl m-auto px-4 py-4 min-h-screen">
      <header className="text-center mb-8">
        <img className="w-full p-1 max-w-full rounded m-auto" src={headerImage} alt="Join Fraternity" />
      </header>

      <img src={membershipCampaignLogo} alt="Membership Campaign Logo" className="mb-6 p-4 max-w-full h-auto m-auto" />

      {/* First Section Container */}
      <div className="bg-blue-900 text-white p-6 rounded-lg mb-8">
        <h2 className="text-xl font-bold mb-4">What is Fraternity?</h2>
        <ul className="list-disc pl-6 space-y-4 marker:text-blue-500">
          <li>Fraternity Movement is a student and youth organisation that began its journey almost eight years ago, with a vision of spreading social justice, democracy and the idea of fraternity in the society.</li>
          <li>With a pan-India base, Fraternity has a significant presence in major central, state and private universities and colleges and a wide base in states like Kerala, West Bengal and others.</li>
          <li>It has intervened in major political, social and educational issues affecting the marginalised communities by organising public meetings, protest gatherings, rallies, press meets and by releasing fact-finding and public reports. Fraternity has also successfully contested several major student union elections.</li>
          <li>It works on a politics of fraternity between the marginalised sections of the country, who face exclusion and alienation in various ways. These sections come together to form the organisation's cadre and leadership, deciding mutually on major issues, policies, the organisation's politics and the way ahead.</li>
        </ul>
      </div>

      <div className="text-neutral space-y-8">
        <section>
          <h2 className="text-xl font-bold mb-4">Why 'Fraternity'?</h2>
          <p>The movement's name, 'Fraternity', sums up an attempt to work towards a better existence encompassed by solidarity and support between the marginalised through mutual consultation and awareness about each other's issues and concerns. It is not a politics that is based on mere tolerance and coexistence. It seeks to bring together and lead the empowerment of the oppressed communities on all grounds: social, political and economic.</p>
        </section>

        <section>
          <h2 className="text-xl font-bold mb-4">Why should you join Fraternity?</h2>
          <div className="space-y-4">
            <p>Fraternity believes that the only way out of the shrinking space for dissent and the political crackdowns is to come together in the form of associations which dare to take up the issues of the oppressed. It also stresses on addressing the challenges faced by the students and the youth who constitute this country's future.</p>
            <p>An honest, ethical and committed political movement led by organic leaders from the students and youth of this country, who knows the ground, is the most important need of the hour to challenge the ever increasing money and muscle power. Fraternity believes that association of a broad coalition of voices is the only way we can conclusively reach solutions to our shared issues and concerns.</p>
          </div>
        </section>

        {/* Last Section Container */}
        <div className="bg-neutral text-white p-6 rounded-lg mt-8">
          <img src={sloganImage} alt="Social Revolution" className="p-4 max-w-full h-auto m-auto" />
          <p className="font-bold my-4">Membership Campaign: November 15, 2024 - December 15, 2024</p>
          <div className="space-y-4">
            <p>Through this month-long campaign, Fraternity aims to centre the politics of justice, which is under serious attack, and also celebrate how marginalised communities and individuals continue to creatively and comprehensively counter this onslaught. We celebrate those icons in our midst and in the years gone by who have taught us ways to survive and thrive.</p>
            <p>Although, as students and youth, we are deeply aware of how insidious and deep this politics of hate has percolated, we simultaneously believe that a future-focused orientation will allow us to devise strategies to organise, collectively think, and move forward.</p>
            <p>Our dreams will not be bulldozed even if our houses are; our optimism for a better future cannot be erased even if textbooks are 'revised', our leaders are thrown behind bars, or student politics is repressed!</p>
            <p>We invite you to join Fraternity and help us sharpen our vision, work together collectively and build solidarities to deepen social justice and pave way for a fearless and dignified coexistence.</p>
          </div>
        </div>
      </div>

      <div className="py-14" />

      <div className="py-8 text-center fixed bottom-0 left-0 right-0 bg-gradient-to-b from-transparent to-white">
        <Link to="/register" className="btn btn-lg btn-neutral border-none text-white btn-wide shadow-lg">
          Join Now
        </Link>
      </div>
    </div>
  );
}
