import { Link } from "react-router-dom";
import headerImage from "./assets/header-image.jpeg";
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import fratMembershipCardTemplate from './assets/fraternity-membership-card-template.pdf';
import download from "downloadjs";

function Success() {
    const downloadPDF = async () => {
        const memberName = localStorage.getItem('memberName');
        const memberId = localStorage.getItem('memberId');

        // Implement error screen here if no memberName

        const url = fratMembershipCardTemplate;
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
      
        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
      
        const pages = pdfDoc.getPages()
        const firstPage = pages[0]
        firstPage.drawText( memberName , {
          x: 236,
          y: 246,
          size: 28,
          font: helveticaFont,
          color: rgb(0.098, 0.46, 0.82),
        })
        const dateToday = new Date().toLocaleDateString();
        firstPage.drawText( dateToday , {
            x: 100,
            y: 100,
            size: 16,
            font: helveticaFont,
            color: rgb(0.1,0.1,0.1),
          })
          firstPage.drawText( memberId , {
            x: 200,
            y: 100,
            size: 12,
            font: helveticaFont,
            color: rgb(0.5,0.5,0.5),
          })
      
        const pdfBytes = await pdfDoc.save()
        download(pdfBytes, memberName + "-membership-card", "application/pdf");
      };
    
    return (
        <div className="max-w-lg m-auto p-4 text-center min-h-screen">
            <header>
                <img className="w-100 p-1 max-w-full rounded m-auto" src={headerImage} alt="Join Fraternity" />
            </header>
                <div className="mt-2 mb-32 card bg-success">
                    <div className="m-2 py-4 card bg-neutral text-primary-content">
                        <div className="card-body">
                            <h2 className="card-title justify-center">Thank you!</h2>
                            <p>Your membership is registered successfully 🤝</p>
                            <div className="mt-4 card-actions justify-center">
                                <button onClick={downloadPDF} className="btn bg-sky-600 border-none text-white btn-wide shadow-lg">Download Membership Card</button>
                            </div>
                            <div className="mt-4 card-actions justify-center">
                                <Link to="/" className="btn btn-sm normal-case">Return Home</Link>
                            </div>
                        </div>
                    </div>
                </div>

            <footer className="footer footer-center pt-16 text-base-content">
                <div>
                    <Link to="/" className="btn btn-sm btn-ghost">🏠 Home</Link>
                    <p>Help: <a href="tel:+919870367010">+91 9870367010</a></p>
                </div>
            </footer>
        </div>
    );
}

export default Success;
